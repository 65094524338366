let initPromise;
let pendingShare = false;

function kakaoTalkInit(jsKey) {
  if (!initPromise) {
    initPromise = new Promise((resolve) => {
      const el = document.createElement('script');
      el.async = true;
      el.src = '//developers.kakao.com/sdk/js/kakao.min.js';
      el.onload = () => {
        window.Kakao.init(jsKey);
        resolve();
      };

      document.getElementsByTagName('head')[0].appendChild(el);
    });
  }

  return initPromise;
}

function kakaoTalkShare(webUrl) {
  const metaContent = (propName) => document
    .querySelector(`meta[property="${propName}"]`)
    .getAttribute('content');

  const kakaoContent = {
    title: metaContent('og:title'),
    description: metaContent('og:description'),
    imageUrl: metaContent('og:image'),
    link: {
      webUrl,
    },
  };

  window.Kakao.Link.sendDefault({
    objectType: 'feed',
    content: kakaoContent,
    // eslint-disable-next-line no-console
    success: (r) => console.log(r),
    // eslint-disable-next-line no-console
    fail: (e) => console.log(e),
  });
}

async function kakaoTalkEvent({ url, jsKey }) {
  if (!pendingShare) {
    pendingShare = true;
    await kakaoTalkInit(jsKey);
    kakaoTalkShare(url);
    pendingShare = false;
  }
}

document.addEventListener('share-kakaoTalk', (ev) => kakaoTalkEvent(ev.detail));
