const onShoppingPage = window.HP.entry.sectionSlug === 'huffpost-shopping';

if (onShoppingPage) {
  document.addEventListener('click', (e) => {
    const { target } = e;

    if (target.tagName === 'A' || target.tagName === 'STRONG') {
      if (target.closest('a').rel === 'sponsored' && target.closest('.listicle-item-container')) {
        const affiliateClient = target.closest('.listicle-item-container').dataset.varsAffiliate;
        const href = target.href || target.closest('a').href;
        const options = {
          affiliate: affiliateClient,
          href,
          retailers: [affiliateClient],
        };

        if (window.permutive) {
          window.permutive.track('AffiliateLinkClick', options);
        }
        e.stopPropagation();
      }
    }
  });
}
